@import "../css/_variables";
.excalidraw {
  .Tooltip {
    position: relative;
  }

  .Tooltip__label {
    --arrow-size: 4px;
    visibility: hidden;
    width: 10ch;
    background: $oc-black;
    color: $oc-white;
    text-align: center;
    border-radius: 4px;
    padding: 4px;
    position: absolute;
    z-index: 10;
    font-size: 0.7rem;
    line-height: 1.5;
    top: calc(100% + var(--arrow-size) + 3px);
    // extra pixel offset for unknown reasons
    left: calc(-50% + var(--arrow-size) / 2 - 1px);
    word-wrap: break-word;

    &::after {
      content: "";
      border: var(--arrow-size) solid transparent;
      border-bottom-color: $oc-black;
      position: absolute;
      bottom: 100%;
      left: calc(50% - var(--arrow-size));
    }
  }

  // the following 3 rules ensure that the tooltip doesn't show (nor affect
  // the cursor) when you drag over when you draw on canvas, but at the same
  // time it still works when clicking on the link/shield
  body:active .Tooltip:not(:hover) {
    pointer-events: none;
  }

  body:not(:active) .Tooltip:hover .Tooltip__label {
    visibility: visible;
  }

  .Tooltip__label:hover {
    visibility: visible;
  }
}
